<template>
  <div class="layout popup">
    <component :is="ErrorUI" v-if="error" :state="error" :close="true" :key="`${error}-error`" />
    <slot />
  </div>
</template>

<script lang="ts">
import { storeToRefs } from 'pinia'
import { useGlobalStore } from '~/store/global'
// import Error from '~/component/state/error.vue'

export default {
  async setup () {

    // 글로벌 스토어
    const { error } = storeToRefs(useGlobalStore())
    
    // 메모리 비우기
    function restore () {
      ErrorUI.value = null
    }

    // 
    onBeforeUnmount (() => {
      restore()
    })

    // 비동기 함수를 만들어서 컴포넌트를 로딩 ---------------------------------------------------------
    const ErrorUI: any = shallowRef(null)
    const loadComponent = async () => {
      const ErrorUI_load = await import('~/component/state/error.vue')
      ErrorUI.value = ErrorUI_load.default || ErrorUI_load
    }
    await loadComponent()
    // ---------------------------------------------------------------------------------------------

    return {
      error,

      // 컴포넌트
      ErrorUI
    }
  }
}
</script>
